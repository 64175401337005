import { createContext, useState } from 'react';

export const UserContext = createContext({ jwt: '', isLoggedIn: false });

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({ jwt: '', isLoggedIn: false });

    const login = (jwt) => {
        setUser({
            jwt: jwt,
            isLoggedIn: true
        });
    };

    const logout = () => {
        setUser({
            jwt: '',
            isLoggedIn: false
        });
    };
    
    return (
        <UserContext.Provider value={{ user, login, logout }}>
            {children}
        </UserContext.Provider>
    );
}