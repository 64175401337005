import React, { useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import useCustomAnalytics from './../hooks/useCustomAnalytics';

ReactGA.initialize("UA-16803127-15");

const withAnalytics = (WrappedComponent, options = {}) => {
    const trackPage = page => {
        ReactGA.set({
            page,
            ...options
        });
        ReactGA.pageview(page);
    };

    const getScrollPercent = () => {
        var h = document.documentElement,
            b = document.body,
            st = 'scrollTop',
            sh = 'scrollHeight';
        return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
    }

    const HOC = props => {
        const scrollTwentyFive = useRef(false);
        const scrollFifty = useRef(false);
        const scrollSeventyFive = useRef(false);
        const scrollOneHundred = useRef(false);
        const [newVisit, sendEvent, sendPageExitEvent, sendPageVisibleEvent, sendPageHiddenEvent] = useCustomAnalytics();

        useEffect(() => {
            if (process.env.REACT_APP_ENVIRONMENT !== 'dev') {
                //don't track visits in dev
                trackPage(props.location.pathname);
                newVisit();
            }

            const scrollHandler = () => {
                const scrollPercent = getScrollPercent();

                if (scrollPercent >= 25 && !scrollTwentyFive.current) {
                    sendEvent({ "scrolldepth": "25%" });
                    scrollTwentyFive.current = true;
                }

                if (scrollPercent >= 50 && !scrollFifty.current) {
                    sendEvent({ "scrolldepth": "50%" });
                    scrollFifty.current = true;
                }

                if (scrollPercent >= 75 && !scrollSeventyFive.current) {
                    sendEvent({ "scrolldepth": "75%" });
                    scrollSeventyFive.current = true;
                }

                if (scrollPercent >= 90 && !scrollOneHundred.current) {
                    sendEvent({ "scrolldepth": "100%" });
                    scrollOneHundred.current = true;
                }
            }

            document.addEventListener('scroll', scrollHandler);

            const beforeUnloadHandler = () => {
                sendPageExitEvent();
                return undefined;
            }
            const DOMContentLoadedHandler = (event) => {
                window.addEventListener('beforeunload', beforeUnloadHandler);
            }
            document.addEventListener("DOMContentLoaded", DOMContentLoadedHandler);

            const visibilitychangeHandler = () => {
                if (document.visibilityState === 'hidden') {
                    sendPageHiddenEvent();
                }
                if (document.visibilityState === 'visible') {
                    sendPageVisibleEvent();
                }
            }
            document.addEventListener('visibilitychange', visibilitychangeHandler);

            return () => {
                document.removeEventListener("scroll", scrollHandler);
                window.removeEventListener("beforeunload", beforeUnloadHandler);
                document.removeEventListener("DOMContentLoaded", DOMContentLoadedHandler);
                document.removeEventListener("visibilitychange", visibilitychangeHandler);
            }
        }, [props.location.pathname]);

        return <WrappedComponent {...props} />;
    };

    return HOC;
};

export default withAnalytics;