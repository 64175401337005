import { useRef } from 'react';
import axios from 'axios';
import config from '../envConfig/index';

const useCustomAnalytics = () => {
    const visitorId = useRef(-1);

    const sendBeaconEvent = (evt) => {
        if (visitorId.current !== -1) {
            navigator.sendBeacon(`${config.analyticsApiBaseUrl}/website-analytics/visitor/${visitorId.current}/event`, evt);
        }
    }

    const newVisit = async () => {
        try {
            const response = await axios({
                url: `${config.analyticsApiBaseUrl}/website-analytics/visitor`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    uri: window.location.href,
                    referrer: document.referrer
                }
            });

            if (response.data.id) {
                visitorId.current = response.data.id;
            }
        } catch (error) {
            // error handling
        }
    }

    const sendEvent = async (evt) => {
        if (visitorId.current !== -1) {
            await axios({
                url: `${config.analyticsApiBaseUrl}/website-analytics/visitor/${visitorId.current}/event`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    events: evt
                }
            });
        } else {
            //id = -1
            //event happened before new visitor created
        }
    }

    const sendPageExitEvent = () => {
        sendBeaconEvent(JSON.stringify({
            events: {
                page: 'exit'
            }
        }));
    }

    const sendPageVisibleEvent = () => {
        sendBeaconEvent(JSON.stringify({
            events: {
                page: 'visible'
            }
        }));
    }

    const sendPageHiddenEvent = () => {
        sendBeaconEvent(JSON.stringify({
            events: {
                page: 'hidden'
            }
        }));
    }

    return [newVisit, sendEvent, sendPageExitEvent, sendPageVisibleEvent, sendPageHiddenEvent];
}

export default useCustomAnalytics;