import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import '../../node_modules/font-awesome/scss/font-awesome.scss';
import '../assets/scss/style.scss';

import Loader from './layout/Loader';
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";
import NavbarComponent from '../Pages/common/navbar';
import { UserProvider } from '../contexts/UserContext';

import PrivateRoute from '../PrivateRoute';
import Error404Page from './../Pages/Maintenance/404';

function App(props) {
    const menu = routes.map((route, index) => {
        if (route.private) {
            //private route requires log in
            return (route.component) ? (
                <PrivateRoute
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    component={props => (
                        <>
                            <Helmet>
                                <title>{route.name}</title>
                                {route.description ?
                                    <meta name="description" content={route.description} /> : <div></div>
                                }
                            </Helmet>
                            <route.component {...props} />
                        </>
                    )} />
            ) : (null);
        } else {
            return (route.component) ? (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                        <>
                            <Helmet>
                                <title>{route.name}</title>
                                {route.description ?
                                    <meta name="description" content={route.description} /> : <div></div>
                                }
                            </Helmet>
                            <route.component {...props} />
                        </>
                    )} />
            ) : (null);
        }
    });

    return (
        <>
            <UserProvider>
                <ScrollToTop>
                    <div className="wrapper">
                        <NavbarComponent />
                        <div className="main" id="main">
                            <Suspense fallback={<Loader />}>
                                <Switch>
                                    {menu}
                                    <Route path="*">
                                        <>
                                            <Helmet>
                                                <title>Page Not Found</title>
                                            </Helmet>
                                            <Error404Page />
                                        </>
                                    </Route>
                                </Switch>
                            </Suspense>
                        </div>
                    </div>
                </ScrollToTop>
            </UserProvider>
        </>
    );
}

export default App;
