import React, { useContext } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import logo from '../../assets/images/ehealthcareers_logo.png';
import { Link } from 'react-router-dom';
import navLinks from '../../navLinks';
import { UserContext } from './../../contexts/UserContext';

const NavbarComponent = () => {

    const { user } = useContext(UserContext);

    const renderNavLinks = (navLinks) => {
        return navLinks.map((link, index) => {
            if ((link.loggedInOnly && user.isLoggedIn) || (!link.loggedInOnly && !link.loggedOutOnly) || (link.loggedOutOnly && !user.isLoggedIn)) {
                return link.href ? 
                <li className="nav-item" key={index}>
                    <Nav.Link className="nav-link" as="a" href={link.href} eventKey={index}>{link.display}</Nav.Link>
                </li> : 
                <li className="nav-item" key={index}>
                    <Nav.Link className="nav-link" as={Link} to={link.to} eventKey={index}>{link.display}</Nav.Link>
                </li>
            } else {
                return <></>;
            }
        });
    }

    return (<>
        <Navbar sticky="top" bg="light" expand="lg" className="navbar-default navbar-fixed-top past-main" collapseOnSelect>
            <div className="container">
                <Navbar.Brand href="/"><img src={logo} className="logo" alt="ehealth careers logo" /></Navbar.Brand>
                <Navbar.Toggle data-toggle="collapse" data-target=".navbar-collapse" aria-controls="basic-navbar-nav"><span className="navbar-toggler-icon" /></Navbar.Toggle>
                <Navbar.Collapse id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto" />
                    <ul className="navbar-nav my-2 my-lg-0">
                        {renderNavLinks(navLinks)}
                    </ul>
                </Navbar.Collapse>
            </div>
        </Navbar>
    </>);
}

export default NavbarComponent;