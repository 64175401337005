import React from 'react';
import withAnalytics from './hoc/withAnalytics';

const Landing = React.lazy(() => import('./Pages/Landing/index'));
const SignUp = React.lazy(() => import('./Pages/Authentication/SignUp/index'));
const Signin = React.lazy(() => import('./Pages/Authentication/SignIn/index'));
const OrganizationList = React.lazy(() => import('./Pages/Organization/OrgList/index'));
const OrganizationDetail = React.lazy(() => import('./Pages/Organization/OrgDetail/index'));
const OrganizationEdit = React.lazy(() => import('./Pages/Organization/OrgEdit/index'));
const TermsPage = React.lazy(() => import('./Pages/Terms/index'));
const PrivacyPage = React.lazy(() => import('./Pages/Privacy/index'));

const route = [
    { path: '/', exact: true, name: 'eHealth Careers', private: false, component: withAnalytics(Landing) },
    { path: '/auth/signup', exact: true, name: 'Signup', private: false, component: withAnalytics(SignUp) },
    { path: '/auth/signin', exact: true, name: 'Signin', private: false, component: withAnalytics(Signin) },
    { path: '/organization/list', exact: true, description: 'A directory of all ehealth organizations in Canada. These include both publicly and privately owned businesses and consulting firms, government bodies, hospitals, and non-profits. Hospitals and other non-ehealth-exclusive organizations are listed below only if they hire in-house ehealth professionals as full-time staff.', name: 'Canadian ehealth Organizations Directory', private: false, component: withAnalytics(OrganizationList) },
    { path: '/organization/detail/:orgSlug', exact: true, name: 'Organization Detail', private: false, component: withAnalytics(OrganizationDetail) },
    { path: '/organization/edit', exact: true, name: 'Edit Organization', private: true, component: withAnalytics(OrganizationEdit) },
    { path: '/terms', exact: true, name: 'Terms and Conditions', private: false, component: withAnalytics(TermsPage) },
    { path: '/privacy', exact: true, name: 'Privacy Policy', private: false, component: withAnalytics(PrivacyPage) }
];

export default route;
