import React, { useContext } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { UserContext } from './contexts/UserContext';

function PrivateRoute({ ...props }) {
  const { user } = useContext(UserContext);
  const history = useHistory();

  if (user.isLoggedIn) {
    return (<Route
      {...props}
    />);
  } else {
    return (
      <Redirect to={{ pathname: '/auth/signin', state: { referrer: `${history.location.pathname}${history.location.search}` } }} />
    );
  }
}

export default PrivateRoute;