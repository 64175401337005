import React from 'react';
import { NavLink } from 'react-router-dom';

const Error404Page = () => {
    return (
        <>
            <div className="auth-wrapper offline">
                <div className="text-center">
                    <h1 className="mb-4">404 Page Not Found</h1>
                    <p className="mb-4">The page you are looking for may have been moved, or removed altogether. Try going to the homepage to see if you can find what you are looking for.</p>
                    <NavLink to="/" className="btn btn-primary mb-4"><i className="feather icon-home" />Home</NavLink>
                </div>
            </div>
        </>
    );
}

export default Error404Page;